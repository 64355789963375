html {
    @include font-size(html, true);
    // scroll-behavior: smooth;

    // Hide scrollbars
    &::-webkit-scrollbar {
        display: none;
        width: 0px;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
}
