$breakpoints: (
    xs: 440px, // Extra small and up
    sm: 620px, // Small and up
    md: 720px, // Medium and up
    lg: 1000px, // Large and up
    xl: 1240px, // Extra large and up
);

// Grids max-width
$container-width: (
    base: 72rem,
    medium: 60rem, // (72 / 12) * 10
    small: 48rem, // (72 / 12) * 8
    xsmall: 28rem,
);

// Spacings and margins
$gutter: 1.45rem;
$gutter--large: 3.35rem;
$gutter--vertical: 2.6rem;
$gutter--sub-title: 1.05rem;
$gutter--input: 0.5rem;

// Indention
$indention: 2.2rem;

// Borders and strokes
$icon-stroke: 2;
$icon-stroke--thin: 1.44;
$icon-stroke--xthin: 1.28;

$border-width--thin: 1px;
$border-width: 2px;
$border-radius: 4px;
$border-style: $border-width solid $color-text;
$border-style--subtle: $border-width solid rgba(black, 0.1);
$border-style--thin: $border-width--thin solid rgba(black, 0.1);

// Better easing
$ease-in-out-expo: cubic-bezier(1, 0, 0, 1);
$ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);

$ease-in-out-quart: cubic-bezier(0.77, 0, 0.175, 1);
$ease-out-quart: cubic-bezier(0.165, 0.84, 0.44, 1);
$ease-in-quart: cubic-bezier(0.895, 0.03, 0.685, 0.22);

$ease-in-out-quint: cubic-bezier(0.86, 0, 0.07, 1);
$ease-in-out-quint--custom: cubic-bezier(0.86, 0, 0.4, 1);
$ease-in-out-quint--natural: cubic-bezier(0.3, 0, 0.2, 1);

$ease-in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
$ease-out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$ease-in-quad: cubic-bezier(0.55, 0.085, 0.68, 0.53);

$ease-in-out-sine: cubic-bezier(0.37, 0, 0.63, 1);

$ease-out-back: cubic-bezier(0.175, 0.885, 0.32, 1.275);
$ease-out-back--subtle: cubic-bezier(0.175, 0.885, 0.32, 1.17);

// Background fade-out-transiton
$background-transition: background-color 500ms $ease-out-quad;
$color-transition: color 500ms $ease-out-quad;
$border-transition: border-color 500ms $ease-out-quad;

// Z-index levels
$level-1: 10;
$level-2: 20;
$level-3: 30;
$level-4: 40;

// Fixed heights
// $bar-height: 4rem;
$_bar-height: 72px;
$_bar-height--large: 80px;
$bar-height: var(--bar-height);
$bar-height--large: var(--bar-height--large);
$navbar-height: $_bar-height--large;
$progress-height: $_bar-height;
$close-size: 34px;

// Modal variables
$border-radius-modal: 10px;

// Shadows
$shadow: 0 0 32px rgba(black, 0.05);

// Webpage
$page-header-height: $gutter * 2 + 2rem;
