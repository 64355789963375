@import '~_shared/styles/helpers';


.course-video {
    $this: &;
    $indicator-height: $close-size;
    $indicator-width: 56px;
    $progress-size: 3px;
    $controls-height: 5.6rem;
    $controls-fade-out-delay: 1500ms;

    display: flex;
    flex-direction: column;
    user-select: none;
    height: calc(var(--vh) * 100);
    padding-bottom: $controls-height;

    &__video-wrapper {
        position: relative;
        width: 100%;
        overflow: hidden;
        max-height: calc(100vh - #{$controls-height});
        flex-shrink: 0;

        &--done {
            overflow: visible;
            max-height: none;
        }
    }

    &__video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }

    &__next-overlay {
        position: absolute;
        z-index: 2;
        display: flex;
        padding: 0 $gutter--large;
        padding-top: calc(#{var(--statusbar-height)} + 5rem);
        flex-direction: column;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;
        width: 100%;
        min-height: 100%;
        background-color: var(--color-base);
    }

    &__next-thumbnail {
        width: 100%;
        animation: video-next-item 600ms $ease-out-quart both;
        animation-delay: 100ms;
    }

    &__aspect {
        width: 100%;
        background-color: $color-dark;
    }

    &__overlay {
        $margin: $gutter;
        position: absolute;
        display: flex;
        bottom: $margin;
        left: $margin;
        width: calc(100% - #{$margin * 2});
    }

    &__progress {
        @include tap-highlight(true, 0.1);
        position: relative;
        flex-grow: 2;
        display: block;
        height: $indicator-height;
        cursor: pointer;
        overflow: hidden;
        padding: 0 ($indicator-width / 2);
        transition: opacity 500ms $ease-out-quad;
        outline: 0;

        &:before {
            content: '';
            position: absolute;
            top: calc(50% - #{$progress-size / 2});
            left: 0;
            width: 100%;
            border-radius: $progress-size;
            height: $progress-size;
            background-color: rgba(white, 0.3);
        }

        &--hidden {
            transition-delay: $controls-fade-out-delay;
            opacity: 0;

            &:hover,
            &:focus {
                opacity: 1;
            }
        }
    }

    &__progress-wrapper {
        @include tap-highlight;
        position: relative;
        display: block;
        height: inherit;
        cursor: pointer;
    }

    &__progress-bar {
        display: block;
        position: absolute;
        top: calc(50% - #{$progress-size / 2});
        left: 0;
        height: $progress-size;
        width: 100%;
        transform-origin: left;
        background-color: var(--color-base);

        &:before {
            content: '';
            position: absolute;
            height: inherit;
            width: $indicator-width;
            top: 0;
            left: -($indicator-width);
            background-color: inherit;
        }
    }

    &__progress-indicator {
        @include font-size(small);
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding-bottom: 1px;
        outline: 0;
        position: absolute;
        top: calc(50% - #{$indicator-height / 2});
        right: -($indicator-width / 2);
        height: $indicator-height;
        width: $indicator-width;
        border-radius: $border-radius;
        background-color: var(--color-base);

        &--touch {
            box-shadow: 0 0 10px rgba(black, 0.1);
        }
    }

    &__controls {
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 0;
        left: 0;
        right: 0;
        height: $controls-height;
        padding-bottom: 0.4em;
        background-color: var(--color-base);
    }

    &__caption {
        @include font-size(sub-title);
        position: relative;
        height: 100%;

        &--small {
            @include font-size(base);
        }
    }

    &__caption-text {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 0.8rem $gutter;
        overflow: hidden;

        &--dummy {
            left: -200%;
        }
    }

    &__control {
        @include tap-highlight;
        $size: 58px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: $size;
        height: $size;
        outline: 0;
        margin: 0 0.75rem;
        color: $color-dark;
        border-radius: $border-radius;

        &--fill {
            background-color: var(--color-highlighted);
        }
    }

    &__replay {
        @include font-size(base);
    }

    &__replay-icon {
        margin-right: 0.32rem;
        margin-left: -1px;
        margin-top: -1px;
    }

    &__next {
        position: relative;
        z-index: 2;
        display: block;
        margin: $gutter auto 0;
        width: 100%;

        animation: video-next-item 600ms $ease-out-quart both;
        animation-delay: 150ms;
    }

    &__mute {
        @include tap-highlight;
        flex-shrink: 0;
        flex-grow: 0;
        margin-left: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 3;
        width: $indicator-height;
        height: $indicator-height;
        border-radius: $border-radius;
        color: $color-dark;
        background-color: var(--color-base);
        outline: 0;
        transition: opacity 500ms $ease-out-quad;

        &--hidden {
            transition-delay: $controls-fade-out-delay;
            opacity: 0;
        }
    }

    &__sound-icon {
        &--muted {
            margin-right: -1px;
        }
    }
}

@keyframes video-next-item {
    from {
        opacity: 0;
        transform: translateY(1.6rem);
    }
}
