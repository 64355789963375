h1,
h2,
h3,
h4 {
    // @include hyphens;
    @include font(base, normal);
    margin: 0 0 0.8rem;
}

h1 {
    @include font-size(title);
}

h2 {
    @include font-size(sub-title);
}

h3 {
    @include font-size(base);
}

// Paragraph
p {
    @include font(base, normal);
}


// Strong / Bold
strong,
b {
    @include font(base, normal);
}

em {
    // @include font(base, normal);
    color: $color-muted;
}
