// No custom antions, only very basic ones
@keyframes fade-in {
    from {
        opacity: 0;
    }
}

@keyframes fade-out {
    to {
        opacity: 0;
    }
}

@keyframes skeleton {
    0% {
        transform: scaleX(0);
    }

    30%,
    70% {
        transform: scaleX(1);
    }

    100% {
        transform: translateX(100%) scaleX(0.001);
    }
}


@keyframes item-enter {
    from {
        opacity: 0;
        transform: translateY(1rem);
    }
}

@keyframes item-exit {
    to {
        opacity: 0;
        // transform: translateY(1rem);
    }
}
