@import '~_shared/styles/helpers';

.charge-recipe {
    $this: &;

    &__item {
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: $gutter 10px;
        border-radius: $border-radius;
        background-color: var(--color-highlighted);
    }

    &__details {
        color: var(--color-muted);
        margin-top: 1rem;
    }

    &__detail {
        margin: 0 0.3em;
    }
}
