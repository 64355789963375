$fonts: (
    base: (
        font: 'Riposte',
        fallback: sans-serif,
        normal: normal,
    ),
);

$breakpoint-xl: strip-unit(map-get($breakpoints, xl));
$font-min: 18;
$font-max: 20;

$font-size: (
    html: (
        base: (#{$font-min}px, 1),
        md: (calc(#{$font-min}px + (#{$font-max} - #{$font-min}) * ((100vw - 420px) / (#{$breakpoint-xl} - 420))), 1),
        xl: (#{$font-max}px, 1),
    ),
    small: (
        base: (16px, 1.2),
    ),
    base: (
        base: (1rem, 1.25),
    ),
    sub-title: (
        base: (1.35rem, 1.3),
        md: (1.75rem, 1.3),
    ),
    preamble: ( // TODO: Rename?
        base: (1rem, 1.25),
        md: (1.25rem, 1.3),
    ),
    medium: ( // TODO: Rename?
        base: (1.8rem, 1.25),
        xl: (3rem, 1.25),
    ),
    title: (
        base: (2.5rem, 1.15),
        md: (2.6rem, 1.2),
        lg: (3rem, 1.2),
        xl: (4rem, 1.15),
    ),
    title-large: (
        base: (3.1rem, 1.15),
        xl: (4rem, 1.15),
    ),
);
