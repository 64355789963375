a {
    @include font(base, normal);
    cursor: pointer;
    text-decoration: none;

    color: inherit;

    &:hover,
    &:focus {
        color: inherit;
    }

    &:not([class]) {
        text-decoration: underline;
        text-decoration-thickness: 0.075em;
    }
}
