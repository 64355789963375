$neutral-base: #686667;

$colors: (
    primary: (
        base: #000,
    ),
    neutral: (
        xdark: #000,
        dark: #222,
        base: $neutral-base,
        light: tint($neutral-base, 50%),
        xlight: tint($neutral-base, 85%),
    ),
);

$color-light: #fff;
$color-dark: color(neutral, xdark);

// Specific colors.
// Variables are there to be reused, so being to specific or creating to many will create the opposite situation
$color-background: $color-light;

$color-backdrop: black;

$color-text: $color-dark;
$color-muted: rgba($color-text, 0.45);
$color-muted-bg: rgba(black, 0.2);
$color-links: $color-text;
$color-links--hover: $color-text;

$color-warning: #c00010;
$color-success: #53a045;

$color-outline: rgba(white, 0.3);
